import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';


import FacebookIcon from '@static/icons/facebook.svg'

const SOCIAL = [
  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/Elite-Cleaning-Services-111707180511726/'
  }
];

const Footer = () => (
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              &copy; {' '}{new Date().getFullYear()}{' '}Elite Cleaning Services
            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt="link" />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 20px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 24px 0;
    @media (max-width: ${props => props.theme.screen.sm}) {
        padding: 5px 0;
    }
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 5px 0;
  }
`;

export default Footer;
