import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 18px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

export const Section = styled.section`
  padding: 60px 0;
  overflow: hidden;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 96px 0;
  }

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`;

export const HeaderWrapper = styled.header`
  background: rgb(233,246,252, 0.5);
  {/*background: linear-gradient(160deg, rgba(233,246,252,1) 75%, rgba(16,0,255,1) 80%,  rgba(0,212,255,1) 100%);*/}
  padding-top: 50px;
  padding: 120px 10px;
  height: 100vh;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 60px;
    padding-bottom: 130px;
  }
`;

export const HeaderMainText = styled.h1`
  color: #323232;
  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 2px;
  padding: 30px 19px 0 0;
  margin: 0 0 14px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    font-size: 22px;
  }
`;

export const Text = styled.div`
  justify-self: center;
  text-align: center;
  
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`;

export const HeaderSubMainText = styled.h2`
  color: #7e7e7e;
  margin: 0 75px 0 70px;
  padding: 5px 70px 15px 70px;
  font-size: 14px;
  text-align: left;
  line-height: 30px;
  text-transform: none;
    @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 12px;
    margin: 0 3px 0 3px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
