import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'An absurd gatsby starter',
  title: 'Elite Cleaning Services - Professional/Cost effective based in Meath providing services including Facility Management, Builders or Tenant Clean, Windows, Gutters, Power Washing, and Covid-19 Cleaning service',
  url: 'https://elitecleaningservices.ie/',
  author: 'Mark Slyman',
  keywords: ['Cleaning', 'service', 'Professional', 'Cost', 'effective', 'facility', 'management', 'tenant', 'windows', 'gutters', 'power', 'office', 'covid-19', 'meath', 'dublin', 'ireland', 'leinster', 'job', 'quote', 'industrial', 'disinfect', 'high touch', 'dispenser', 'sanitation', 'hospital grade'],
  img: 'https://www.elitecleaningservices.ie/site.png',
  twitterId: 'cleaningservices10',
  facebookId: 'Elite-Cleaning-Services-Meath-Dublin-Ireland-104206544575021',
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebookId} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={SEO_DATA.twitterId} />
      <meta name="twitter:site" content={SEO_DATA.url} />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="en" />
      <style>{      'body:after{\n' +
      '      content:"";\n' +
      '      position:fixed; /* stretch a fixed position to the whole screen */\n' +
      '      top:0;\n' +
      '      height:100vh; /* fix for mobile browser address bar appearing disappearing */\n' +
      '      left:0;\n' +
      '      right:0;\n' +
      '      z-index:-1; /* needed to keep in the background */\n' +
      '      background: url(bg-mobile.png) center center;\n' +
      '      -webkit-background-size: cover;\n' +
      '      -moz-background-size: cover;\n' +
      '      -o-background-size: cover;\n' +
      '      background-size: cover;\n' +
      '}' +
      '' +
      '  @media only screen and (max-width: 767px) {\n' +
      'body:after{\n' +
      '      content:"";\n' +
      '      position:fixed; /* stretch a fixed position to the whole screen */\n' +
      '      top:0;\n' +
      '      height:100vh; /* fix for mobile browser address bar appearing disappearing */\n' +
      '      left:0;\n' +
      '      right:0;\n' +
      '      z-index:-1; /* needed to keep in the background */\n' +
      '      background: url(bg-mobile.png) center center;\n' +
      '      -webkit-background-size: cover;\n' +
      '      -moz-background-size: cover;\n' +
      '      -o-background-size: cover;\n' +
      '      background-size: cover;\n' +
      '}' +
      '   }\n' +
          '  @media only screen and (max-width: 575px) {\n' +
          'body:after{\n' +
          '      content:"";\n' +
          '      position:fixed; /* stretch a fixed position to the whole screen */\n' +
          '      top:0;\n' +
          '      height:100vh; /* fix for mobile browser address bar appearing disappearing */\n' +
          '      left:0;\n' +
          '      right:0;\n' +
          '      z-index:-1; /* needed to keep in the background */\n' +
          '      background: url(bg-mobile-sm.png) center center;\n' +
          '      -webkit-background-size: cover;\n' +
          '      -moz-background-size: cover;\n' +
          '      -o-background-size: cover;\n' +
          '      background-size: cover;\n' +
          '}' +
          '   }\n'

      }</style>
    </Helmet>
  );
};

export default SEO;
